<template>
  <ion-page>
    <div
      v-if="$store.state.userRole !== 'Landlord'"
      class="background-main-view main-view"
    >
      <ion-header>
        <main-view-header />
      </ion-header>
      <!-- House Name -->
      <ion-content
        :scroll-y="false"
      >
        <div class="max-width">
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col>
                <ion-text
                  class="house-name"
                >
                  {{ propertyName }}{{ $t('myHomeTextAfterTheName') }}
                <!-- <i class="material-icons text--gray">expand_more</i> -->
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
          <!-- Weather -->
          <ion-grid class="weather">
            <ion-row>
              <ion-col size="4">
                <div>{{ dayFullname }}</div>
                <ion-text class="today">
                  <i class="material-icons text--gray text-18">event_note</i>
                  <span>{{ today }}</span>
                </ion-text>
              </ion-col>
              <ion-col size="4">
                <div>{{ $t('outside') }}</div>
                <ion-text class="outside">
                  <i
                    class="mdi text--gray text-18"
                    :class="weatherIconMapper(weather)"
                  />
                  <!-- <img
                    style="max-width: 18px"
                    :src="require('../assets/WeatherIcons/' + weather.condition + '.png')"
                  > -->
                  <span>{{ weather ? Math.round(weather.temperature) : 'n/a' }}°C</span>
                </ion-text>
              </ion-col>
              <ion-col size="4">
                <div>{{ $t('inside') }}</div>
                <ion-text class="inside">
                  <i class="material-icons text--gray text-18">thermostat</i>
                  <span>{{ roomTemperature() }}°C</span>
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
          <!-- Scenes -->
          <ion-card-header class="d-flex align-items-center justify-content-between">
            <ion-text class="section-title">
              {{ $t('scenes') }}
            </ion-text>
            <ion-text
              tappable
              class="add-button"
              fill="clear"
              :class="canEditCreateScenes === false ? 'disabled' : ''"
              @click="$router.push('/scenes')"
            >
              <i class="material-icons icon text--gray">tune</i>
            </ion-text>
          </ion-card-header>
          <!-- scenes -->
          <div
            class="p-lr-10 scenes-position"
            :class="canChangeScenes === false ? 'disabled' : ''"
          >
            <keep-alive>
              <swiper
                id="scene"
                ref="scene"
                class="scenes"
                :slides-per-view="2.3"
                :space-between="10"
              >
                <swiper-slide
                  v-for="{ id, sceneName, sceneActive } in propertyScenes"
                  :key="id"
                  tappable
                  :disabled="sceneLoading"
                  class="scene-button"
                  :class="sceneActive ? 'scene-active' : 'scene-inactive'"
                  @click="setScene(id, sceneName)"
                >
                  <span>{{ sceneName }}</span>
                  <!-- <ion-spinner
                    v-else
                    v-if="!sceneLoading || sceneName !== settingScene"
                    class="mt-5"
                    name="dots"
                  /> -->
                </swiper-slide>
              </swiper>
            </keep-alive>
          </div>
          <!-- Rooms -->
          <ion-card-header
            class="d-flex align-items-center justify-content-between"
          >
            <ion-text class="section-title">
              {{ $t('rooms') }}
            </ion-text>
            <ion-text
              class="add-button"
              :class="canEditCreateRooms === false ? 'disabled' : ''"
              fill="clear"
              tappable
              @click="$router.push('/rooms')"
            >
              <i class="material-icons icon text--gray">tune</i>
            </ion-text>
          </ion-card-header>
          <keep-alive>
            <swiper
              id="swiperRooms"
              ref="swiperRoomey"
              class="swiper"
              style="will-change: transform;"
              :effect="'coverflow'"
              :slides-per-view="1.4"
              :space-between="0"
              :auto-update="true"
              :centered-slides="true"
              :pagination="true"
              :pagination-clickable="true"
            >
              <swiper-slide
                v-for="room in propertyRooms"
                :key="room.id"
                :class="`room-button ${room.roomClass}`"
                tappable
                @click="openRoomModal(room.roomName, room.id, room)"
              >
                <div class="slider-top-content">
                  <h2
                    class="room-name"
                  >
                    {{ room.roomName }}
                  </h2>

                  <div
                    v-if="roomTemperature(room.id)"
                    class="d-flex align-items-center justify-content-center w-100"
                  >
                    <i class="material-icons text--gray text-18 mr-10">thermostat</i>
                    {{ roomTemperature(room.id) }}°C
                    <i class="material-icons text--gray text-18 mr-10 ml-20">opacity</i>
                    {{ roomHumidity(room.id) }}%
                  </div>
                  <div
                    v-else
                    class="d-flex align-items-center justify-content-center w-100"
                  />
                </div>
                <div class="room-devices d-flex align-items-center">
                  <!-- <i class="material-icons text-18 mr-10">wifi_tethering</i> -->
                  <img
                    src="../assets/images/wifi.svg"
                    class="mr-10"
                  >
                  <ion-text class="text--gray">
                    {{ devicesInRoom(room.id) }} {{ $t('devices') }}
                  </ion-text>
                </div>
              </swiper-slide>
              <!-- <swiper-slide class="room-button-empty chair-empty">
                <div
                  class="d-flex align-items-center justify-content-center w-100"
                  style="padding-top: 60%"
                >
                  <span
                    class="text--primary"
                  > + Add new room</span>
                </div>
              </swiper-slide> -->
              <div class="swiper-pagination" />
            </swiper>
          </keep-alive>
        </div>
      </ion-content>
    </div>
    <ion-header
      v-if="$store.state.userRole === 'Landlord'"
    >
      <main-view-header />
    </ion-header>
    <ion-content
      v-if="$store.state.userRole === 'Landlord'"
      class="landlord"
    >
      <div class="text-left">
        <div
          class="text--white text-64 ion-padding-start"
        >
          Homevision Dashboard
          <span class="text--primary">
            Overview
          </span>
          <!-- <ion-spinner
              v-show="fetching"
              style="position: absolute; margin-left: 10px;"
              color="light"
              name="crescent"
            /> -->
          <div
            v-show="fetching"
            class="blinking-dot"
          >
            <i class="mdi mdi-circle-medium" />
          </div>
        </div>

        <ion-grid>
          <ion-row>
            <ion-col
              size-xl="5"
              size-lg="12"
              size-md="12"
              size-sm="12"
              size-xs="12"
            >
              <div
                class="chart-col-bg ion-padding"
                tappable
                @click="$router.push('/statistics')"
              >
                <i
                  tappable
                  class="mdi mdi-arrow-right text--white"
                  style="position: absolute; right: 10px; top: 10px; font-size: 25px; z-index: 1000;"
                />
                <div class="text-left">
                  <span class="text--white text-12 text--bold text--uppercase">Portfolio statistics</span>
                </div>
                <div class="text--white ml-10 mt-10">
                  <ion-icon
                    :icon="buildingIco"
                    style="font-size: 15px; margin-right: 5px;"
                    class="text--primary"
                  />
                  {{ landlordBuildings }} buildings
                  <div style="display: inline-block; position: relative; top: 3px; left: 5px; margin-right: 10px;">
                    <i
                      class="mdi mdi-view-quilt text--primary"
                      style="font-size: 20px;"
                    />
                  </div>
                  {{ landlordProperties }} apartments
                </div>
                <div class="bar-wrapper ">
                  <div
                    v-if="Math.round(donutSensorsStatsSerie[0]) !== 0"
                    class="bar-element"
                    style="background-color: #0cd0a1;"
                    :style="`width: ${donutSensorsStatsSerie[0]}%;`"
                  >
                    <span
                      class="value"
                      style="color: black;"
                    >
                      {{ Math.round(donutSensorsStatsSerie[0]) === 0 ? '' : `${Math.round(donutSensorsStatsSerie[0])}%` }}
                    </span>
                  </div>
                  <div
                    v-if="Math.round(donutSensorsStatsSerie[1]) !== 0"
                    style="background-color: #445E93;"
                    class="bar-element"
                    :style="`width: ${donutSensorsStatsSerie[1]}%;`"
                  >
                    <span
                      class="value"
                      style="color: white;"
                    >
                      {{ Math.round(donutSensorsStatsSerie[1]) === 0 ? '' : `${Math.round(donutSensorsStatsSerie[1])}%` }}
                    </span>
                  </div>
                  <div
                    v-if="Math.round(donutSensorsStatsSerie[2]) !== 0"
                    style="background-color: #ffd4a4;"
                    class="bar-element"
                    :style="`width: ${donutSensorsStatsSerie[2]}%;`"
                  >
                    <span
                      class="value"
                      style="color: black;"
                    >
                      {{ Math.round(donutSensorsStatsSerie[2]) === 0 ? '' : `${Math.round(donutSensorsStatsSerie[2])}%` }}
                    </span>
                  </div>
                  <div
                    v-if="Math.round(donutSensorsStatsSerie[3]) !== 0"
                    style="background-color: #eb445a;"
                    class="bar-element"
                    :style="`width: ${donutSensorsStatsSerie[3]}%;`"
                  >
                    <span
                      class="value"
                      style="color: white;"
                    >
                      {{ Math.round(donutSensorsStatsSerie[3]) === 0 ? '' : `${Math.round(donutSensorsStatsSerie[3])}%` }}
                    </span>
                  </div>
                </div>
                <ion-row>
                  <ion-col
                    size-xl="7"
                    size-lg="12"
                    size-md="12"
                    size-sm="12"
                    size-xs="12"
                    class="ion-no-padding text-14"
                  >
                    <div class="text--white">
                      <ul
                        class="list"
                        style="list-style: none; margin-top: 10px; margin-bottom: 0;"
                      >
                        <li
                          class="li1"
                          style="margin-top: 5px;"
                        >
                          <div
                            class="text--primary"
                            style="display: inline-block; width: 40px;"
                          >
                            {{ Math.round(donutSensorsStatsSerie[0]) }}%
                          </div>
                          ({{ serieData[0] }})  apartments online
                        </li>
                        <li
                          class="li2"
                          style="margin-top: 5px;"
                        >
                          <div
                            class="text--primary"
                            style="display: inline-block; width: 40px;"
                          >
                            {{ Math.round(donutSensorsStatsSerie[1]) }}%
                          </div>
                          ({{ serieData[1] }}) apartments needs attention
                        </li>
                        <li
                          class="li3"
                          style="margin-top: 5px;"
                        >
                          <div
                            class="text--primary"
                            style="display: inline-block; width: 40px;"
                          >
                            {{ Math.round(donutSensorsStatsSerie[2]) }}%
                          </div>
                          ({{ serieData[2] }})  apartments not installed
                        </li>
                        <li
                          class="li4"
                          style="margin-top: 5px;"
                        >
                          <div
                            class="text--primary"
                            style="display: inline-block; width: 40px;"
                          >
                            {{ Math.round(donutSensorsStatsSerie[3]) }}%
                          </div>
                          ({{ serieData[3] }})  apartments are offline
                        </li>
                      </ul>
                    </div>
                  </ion-col>
                  <ion-col
                    size-xl="5"
                    size-lg="12"
                    size-md="12"
                    size-sm="12"
                    size-xs="12"
                    class="ion-no-padding text--white text-14"
                  >
                    <div class="mt-10">
                      <i class="mdi mdi-account-group text--primary" />
                      {{ landlordsOverviewPieData.tenantsInPortfolio }} tenants in apartments
                    </div>
                    <div class="mt-5">
                      <i class="mdi mdi-account-check text--primary" />
                      16 peoples are using the platform
                    </div>
                    <div class="mt-5">
                      <i class="mdi mdi-account-alert text--primary" />
                      16 peoples have not registered yet
                    </div>
                  </ion-col>
                </ion-row>
              </div>
            </ion-col>
            <ion-col
              size-xl="7"
              size-lg="12"
              size-md="12"
              size-sm="12"
              size-xs="12"
            >
              <div
                class="chart-col-bg ion-padding"
                tappable
                @click="$router.push('/events')"
              >
                <i
                  class="mdi mdi-arrow-right text--white"
                  tappable
                  style="position: absolute; right: 15px; top: 15px; font-size: 25px; z-index: 1000;"
                />
                <div class="text-left">
                  <span class="text--white text-12 text--bold text--uppercase">Current Events</span>
                </div>
                <ion-row>
                  <ion-col
                    size-xl="6"
                    size-lg="6"
                    size-md="12"
                    size-sm="12"
                    size-xs="12"
                  >
                    <ion-item
                      lines="none"
                      detail="false"
                      class="rounded-corners alarms"
                    >
                      <i
                        slot="start"
                        class="mdi mdi-fire text--white"
                        style="font-size: 40px"
                      />
                      <div
                        slot="start"
                        class="text--white"
                      >
                        <div v-if="alertsInWhole.fire.length < 1">
                          No current fire events
                        </div>
                        <div
                          v-else
                          class="text--danger"
                        >
                          <i
                            class="mdi mdi-alert text--primary"
                            style="margin-right: -3px!important;"
                          />
                          {{ alertsInWhole.fire.length }} fire alarm detected
                        </div>
                        <div class="mt-5 text-14">
                          <span v-if="landlordEvents.last7DaysFireEvent ? landlordEvents.last7DaysFireEvent.length : 0 > 0"><i class="mdi mdi-alert text--primary" /> {{ landlordEvents.last7DaysFireEvent.length }} in past week</span>
                          <span v-else><i class="mdi mdi-check text--green" /> 0 in past week</span>
                        </div>
                        <div class="mt-5 text-14">
                          <span v-if="landlordEvents.last31DaysFireEvent ? landlordEvents.last31DaysFireEvent.length : 0 > 0"><i class="mdi mdi-alert text--primary" /> {{ landlordEvents.last31DaysFireEvent.length }} in past month</span>
                          <span v-else><i class="mdi mdi-check text--green" /> 0 in past month</span>
                        </div>
                      </div>
                    </ion-item>
                  </ion-col>
                  <ion-col
                    size-xl="6"
                    size-lg="6"
                    size-md="12"
                    size-sm="12"
                    size-xs="12"
                  >
                    <ion-item
                      lines="none"
                      detail="false"
                      class="rounded-corners alarms"
                    >
                      <i
                        slot="start"
                        class="mdi mdi-water-outline text--white"
                        style="font-size: 40px"
                        :class="alertsInWhole.leak.length < 1 ? 'text--white' : 'text--primary'"
                      />
                      <div
                        slot="start"
                        class="text--white"
                      >
                        <div v-if="alertsInWhole.leak.length < 1">
                          No current waterleak events
                        </div>
                        <div
                          v-else
                          class="text--primary"
                        >
                          <i
                            class="mdi mdi-alert text--primary"
                            style="margin-right: -3px!important;"
                          />
                          {{ alertsInWhole.leak.length }} waterleak detected
                        </div>
                        <div class="mt-5 text-14">
                          <span v-if="landlordEvents.last7daysLeakEvent ? landlordEvents.last7daysLeakEvent.length : 0 > 0"><i class="mdi mdi-alert text--primary" /> {{ landlordEvents.last7daysLeakEvent.length }} in past week</span>
                          <span v-else><i class="mdi mdi-check text--green" /> 0 in past week</span>
                        </div>
                        <div class="mt-5 text-14">
                          <span v-if="landlordEvents.last31daysLeakEvent ? landlordEvents.last31daysLeakEvent.length : 0 > 0"><i class="mdi mdi-alert text--primary" /> {{ landlordEvents.last31daysLeakEvent.length }} in past month</span>
                          <span v-else><i class="mdi mdi-check text--green" /> 0 in past month</span>
                        </div>
                      </div>
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col
                    size-xl="6"
                    size-lg="6"
                    size-md="12"
                    size-sm="12"
                    size-xs="12"
                  >
                    <ion-item
                      lines="none"
                      detail="false"
                      class="rounded-corners alarms"
                    >
                      <i
                        slot="start"
                        class="mdi mdi-thermometer-low text--white"
                        style="font-size: 40px"
                      />
                      <div
                        slot="start"
                        class="text--white"
                      >
                        <div>No climate warning in last 24hrs</div>
                        <div class="mt-5 text-14">
                          <i class="mdi mdi-check text--green" /> 0 in past week
                        </div>
                        <div class="mt-5 text-14">
                          <i class="mdi mdi-check text--green" /> 0 in past month
                        </div>
                      </div>
                    </ion-item>
                  </ion-col>
                </ion-row>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div style="height: 100px" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { modalController, actionSheetController, menuController } from '@ionic/vue'
import { addOutline } from 'ionicons/icons'
import { App } from '@capacitor/app'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

import dayjs from 'dayjs'
// import VueApexCharts from 'vue3-apexcharts'

import RoomModal from '../components/RoomModal.vue'
import NotificationsModal from '../components/NotificationsModal.vue'
import mainViewHeader from '../components/Headers/MainViewHeaderComponent.vue'
import addSceneModal from '../components/Scenes/AddSceneModal.vue'

import buildingIco from '../assets/images/building-ico.svg'

SwiperCore.use([Pagination])

require('../locales/sv')
require('../locales/en')

export default {
  components: {
    Swiper,
    SwiperSlide,
    mainViewHeader
    // apexchart: VueApexCharts
  },
  data () {
    return {
      buildingIco,
      chartLoading: true,
      userHasNoGateway: null,
      roomSlider: null,
      sceneLoading: false,
      settingScene: '',
      showRooms: true,
      number: 76,
      fetching: false,
      serieData: [],
      addOutline,
      donutSensorsStats: {
        plotOptions: {
          pie: {
            offsetY: 0,
            offsetX: 0,
            donut: {
              size: '60%',
              labels: {
                show: false,
                name: {
                  show: false,
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: undefined,
                  offsetY: 30,
                  formatter: (value) => value
                }
              }
            }
          }
        },
        title: {
          text: 'APARTMENT STATISTICS',
          align: 'left',
          offsetY: 0,
          offsetX: -10,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#ffffff'
          }
        },
        // colors: ['#54f542', '#f54242', '#FFB900', '#FF0000'],
        labels: [],
        responsive: [{
          breakpoint: 1450,
          options: {
            plotOptions: {
              pie: {
                customScale: 1.2,
                offsetY: 10,
                size: 180
              }
            },
            chart: {
              width: '100%'
            },
            legend: {
              position: 'bottom',
              offsetX: -20
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            plotOptions: {
              pie: {
                customScale: 1,
                offsetY: 10,
                size: 180
              }
            },
            chart: {
              width: '100%'
            },
            legend: {
              position: 'right',
              offsetX: -20
            }
          }
        },
        {
          breakpoint: 450,
          options: {
            plotOptions: {
              pie: {
                customScale: 1,
                offsetY: 10,
                size: 180
              }
            },
            chart: {
              width: '100%'
            },
            legend: {
              position: 'bottom',
              offsetX: -20
            }
          }
        }],
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: 0
        },
        legend: {
          offsetY: 10,
          fontSize: '16px',
          labels: {
            colors: 'white'
          }
        },
        tooltip: {
          theme: 'dark',
          formatter: (value) => value.toFixed(2) + ' %'
        }
      },
      donutSensorsStatsSerie: []
    }
  },
  computed: {
    canChangeScenes () {
      return this.$store.state.userData.length ? this.$store.state.userData[0].userCanChangeScenes : undefined
    },
    canEditCreateScenes () {
      return this.$store.state.userData.length ? this.$store.state.userData[0].userCanEditCreateScenes : undefined
    },
    canEditCreateRooms () {
      return this.$store.state.userData.length ? this.$store.state.userData[0].userCanEditCreateRooms : undefined
    },
    userRole () {
      return this.$store.state.userRole
    },
    userProperties () {
      return this.$store.state.userProperties
    },
    propertyScenes () {
      return this.$store.state.propertyScenes
    },
    propertyRooms () {
      return this.$store.state.propertyRooms
    },
    propertyDevices () {
      return this.$store.state.propertyDevices
    },
    propertyName () {
      const name = this.$store.state.userName ? this.$store.state.userName.split(' ') : ['Unavailable']
      return name[0]
    },
    weather () {
      return this.$store.state.weather
    },
    notificationCount () {
      const notifications = this.$store.state.notification
      console.log(this.$store.state.notification)
      let count = 0
      notifications.forEach((element) => {
        if (element) { count++ }
      })
      return count
    },
    gatewayProgress () {
      return this.$store.state.gatewayProgress
    },
    dayFullname () {
      const locale = this.$i18n.locale
      console.log(locale)
      let localeActive = ''
      if (locale === 'EN') {
        localeActive = 'en'
      } else if (locale === 'SE') {
        localeActive = 'sv'
      }
      const day = dayjs().locale(localeActive).format('dddd')
      return day
    },
    today () {
      const locale = this.$i18n.locale
      console.log(locale)
      let localeActive = ''
      if (locale === 'EN') {
        localeActive = 'en'
      } else if (locale === 'SE') {
        localeActive = 'sv'
      }
      const today = dayjs().locale(localeActive).format('D MMM')
      return today
    },
    errorsInWhole () {
      const res = []
      this.$store.state.landlordProperties.forEach(item => {
        if (item.gatewayActive === false) {
          const error = {
            class: 'error',
            type: 'gateway',
            id: item.id,
            propertyName: item.propertyName,
            gatewayActive: item.gatewayActive
          }
          res.push(error)
        }
      })
      this.$store.state.landlordDevices.forEach(item => {
        if (!item.active) {
          const error = {
            class: 'error',
            type: 'deviceInactive',
            active: item.active,
            propertyName: this.propertyNameMapper(item.propertyId),
            id: item.propertyId,
            zigbeeModel: item.zigbeeModel
          }
          res.push(error)
        }
      })
      console.log(res)
      return res
    },
    warningsInWhole () {
      const lowBattery = this.$store.state.landlordDevices.filter(item => item.battery === 1)
      return lowBattery
    },
    alertsInWhole () {
      const result = {
        fire: [],
        leak: []
      }
      this.$store.state.landlordProperties.forEach(item => {
        if (item.fire === true) {
          const alert = {
            class: 'fire',
            id: item.id,
            propertyName: item.propertyName,
            fire: item.fire
          }
          result.fire.push(alert)
        }
        if (item.waterLeak === true) {
          const alert1 = {
            class: 'waterLeak',
            id: item.id,
            propertyName: item.propertyName,
            waterLeak: item.waterLeak
          }
          result.leak.push(alert1)
        }
      })
      return result
    },
    landlordsOverviewPieData () {
      return this.$store.state.landlordOverviewPieData
    },
    landlordEvents () {
      const events = this.$store.state.landlordEvents
      console.log(events)
      return events
    },
    landlordProperties () {
      return this.$store.state.landlordProperties.length
    },
    landlordBuildings () {
      return this.$store.state.landlordBuildings.length
    }
  },
  watch: {
    userProperties (value) {
      if (value[0]) {
        if (!value[0].propertyGateway) {
          this.$router.push({ name: 'AppIntro' })
          this.$store.commit('userHasNoGateway', true)
        } else {
          this.$store.commit('userHasNoGateway', false)
        }
      }
    },
    $route: function () {
      if (this.$route.name === 'MainView' && this.userRole !== 'Landlord') {
        this.initSwiper()
      }
    },
    landlordsOverviewPieData: function (value) {
      if (value) {
        this.calcPie()
      }
    }
  },
  mounted () {
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive && this.userRole !== 'Landlord' && this.$route.name === 'MainView') {
        this.initSwiper()
        if (this.userProperties[0]) {
          if (!this.userProperties[0].propertyGateway) {
            this.$router.push({ name: 'AppIntro' })
            this.$store.commit('userHasNoGateway', true)
          } else {
            this.$store.commit('userHasNoGateway', false)
          }
        }
      }
    })
    if (this.userRole !== 'Landlord' && this.$route.name === 'MainView') {
      this.initSwiper()
    }
    if (this.userRole === 'Landlord') {
      setInterval(() => {
        this.fetching = true
        this.$store.dispatch('getLandlordBuildingsAndProperties').then(() => {
          setTimeout(() => {
            this.fetching = false
          }, 3000)
        })
      }, 3600000)
    }
  },
  methods: {
    async openMenu () {
      await menuController.open()
    },
    initSwiper () {
      console.log('swiper init')
      const swip = document.querySelector('.swiper').swiper
      const swip2 = document.querySelector('.scenes').swiper
      swip.update()
      swip2.update()
      setTimeout(() => {
        swip.update()
        swip2.update()
      }, 500)
    },
    weatherIconMapper (weather) {
      const condition = weather ? weather.condition : 'undefined'
      if (condition === 'undefined') {
        return 'mdi-sync-alert'
      } else {
        return 'mdi-' + condition
      }
    },
    createScene () {
      return modalController
        .create({
          component: addSceneModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            devices: this.propertyDevices,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openRoomModal (whichModal, roomId, roomObject) {
      return modalController
        .create({
          component: RoomModal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            roomId: roomId,
            roomObject: roomObject,
            modalTitle: whichModal,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    openModal (whichModal) {
      let modal
      if (whichModal === 'notifications') {
        modal = NotificationsModal
      }
      return modalController
        .create({
          component: modal,
          cssClass: 'devices-modal',
          swipeToClose: true,
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            modalTitle: whichModal,
            close: () => this.closeModal()
          }
        })
        .then(m => m.present())
    },
    setScene (sceneId, sceneName) {
      this.sceneLoading = true
      this.settingScene = sceneName
      console.log(sceneName)
      const scenes = this.propertyScenes
      const sceneToSet = scenes.filter(item => item.id === sceneId)
      if (!sceneToSet[0].sceneActive) {
        this.$store.dispatch('setPropertyScene', sceneId).then(() => {
          this.sceneLoading = false
          this.settingScene = ''
        })
      }
    },
    closeModal () {
      modalController.dismiss()
    },
    devicesInRoom (roomId) {
      const devices = this.$store.state.propertyDevices
      let count = 0
      for (let i = 0; i < devices.length; ++i) {
        if (devices[i].propertyRoomId === roomId) {
          count++
        }
      }
      return count
    },
    roomTemperature (roomId) {
      const devices = this.$store.state.propertyDevices
      if (!roomId) {
        let count = 0
        let temperature = 0
        devices.forEach(item => {
          if (item.temp && item.typeI !== 'SensorTemperatureOutdoor') {
            count++
            temperature = temperature + item.temp
          }
        })
        return temperature ? Math.round((temperature / count) / 100) : 'n/a'
      } else {
        const found = devices.find(element => element.propertyRoomId === roomId && element.temp !== null && element.typeI !== 'SensorTemperatureOutdoor')
        const temp = found ? Math.round(found.temp / 100) : ''
        return temp
      }
    },
    roomHumidity (roomId) {
      const devices = this.$store.state.propertyDevices
      const found = devices.find(element => element.propertyRoomId === roomId && element.humi !== null && element.typeI !== 'SensorTemperatureOutdoor')
      const temp = found ? Math.round(found.humi / 100) : ''
      return temp
    },
    async presentActionSheet () {
      const actionSheet = await actionSheetController
        .create({
          header: 'My Properties',
          cssClass: 'my-custom-class',
          buttons: [
            {
              text: 'My Home',
              handler: () => {
                console.log('Share clicked')
              }
            },
            {
              text: 'My Cottage',
              handler: () => {
                console.log('Play clicked')
              }
            },
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                console.log('Cancel clicked')
              }
            }
          ]
        })
      await actionSheet.present()

      const { role } = await actionSheet.onDidDismiss()
      console.log('onDidDismiss resolved with role', role)
    },
    deviceNameMapper (zigbeeModel) {
      if (zigbeeModel === 'SensorSmoke') {
        return 'Smoke Sensor'
      } else if (zigbeeModel === 'SensorWaterLeak') {
        return 'Leak Sensor'
      } else if (zigbeeModel === 'SensorMotion') {
        return 'Multi Sensor'
      } else {
        return zigbeeModel
      }
    },
    propertyNameMapper (propertyId) {
      const property = this.$store.state.landlordProperties.filter(item => item.id === propertyId)
      return property ? property[0].propertyName : 'none'
    },
    calcPie () {
      this.chartLoading = true
      const pieData = this.landlordsOverviewPieData
      console.log(pieData)
      const apartmetnsNeedsAtention = pieData.landlordDevicesInactivePropertyIds.filter((v, i, a) => a.indexOf(v) === i).length

      const serieData = []
      this.serieData = [
        pieData.landlordPropertiesGatewayInstalled,
        apartmetnsNeedsAtention,
        pieData.landlordPropertiesLength - pieData.landlordPropertiesGatewayInstalled,
        pieData.landlordPropertiesGatewayInstalledButNotActive
      ]
      const installedApartments = pieData.landlordPropertiesGatewayInstalled / pieData.landlordPropertiesLength * 100
      serieData.push(installedApartments)
      const apartmentsNeedsAtentionPercets = apartmetnsNeedsAtention / pieData.landlordPropertiesLength * 100
      serieData.push(apartmentsNeedsAtentionPercets)
      const notInstalledApartments = (pieData.landlordPropertiesLength - pieData.landlordPropertiesGatewayInstalled - apartmetnsNeedsAtention - pieData.landlordPropertiesGatewayInstalledButNotActive) / pieData.landlordPropertiesLength * 100
      serieData.push(notInstalledApartments)
      const offlineApartmetns = pieData.landlordPropertiesGatewayInstalledButNotActive / pieData.landlordPropertiesLength * 100
      serieData.push(offlineApartmetns)

      this.donutSensorsStatsSerie = serieData
      this.chartLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";

.custom-skeleton {
  text-align: left;
}
ion-skeleton-text {
    --background-rgb: 220,220,220;
}
.skeleton-active-scene {
  display: inline-block;
  margin-right: 10px;
  height: 54px;
  width: 150px;
  border: 1px #ffd5a45e solid;
  border-radius: 5px;
}
.skeleton-scene {
  display: inline-block;
  margin-right: 10px;
  height: 54px;
  width: 150px;
  border: 1px #6464645e solid;
  border-radius: 5px;
}
.skeleton-room {
  display: inline-block;
  margin-right: 10px;
  height: 345px;
  width: 270px;
  border: 1px #6464645e solid;
  border-radius: 10px;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.landlord {
  ion-fab {
    bottom: 25px;
    right: 25px;
  }
  .text-64 {
    font-size: 36px;
}
.flat {
  margin-top: 8px;
  @include bg-box-item;
  --min-height: 60px;
}
.alarms {
  margin-top: 8px;
  @include bg-box-item;
  --min-height: 80px;
  div {
    margin-inline-end: 0px!important;
  }
  i {
    margin-inline-end: 10px!important;
    margin-inline-start: 0px!important;
  }
}
.orange-border {
  border: 1px solid rgba(255, 166, 0, 0.7);
}
.red-border {
  border: 1px solid rgba(255, 0, 0, 0.7);
}
}
.chart-col-bg {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  min-height: 260px;
}
.blinking-dot {
  position: absolute;
  right: 45px;
  top: 10px;
  color: #ffd4a4;
  animation: blinker 2s ease-in-out infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media (max-width: 567px) {
  .blinking-dot.blinking-dot  {
    top: 45px;
    right: 10px;
  }
}

.bar-wrapper {
  margin-top: 10px;
  height: 50px;
  max-width: 100%
}
.bar-element {
  height: 100%;
  width: 0;
  display: inline-flex;
  vertical-align: middle;
  -webkit-transition: width .5s ease-in-out;
  -moz-transition: width .5s ease-in-out;
  -o-transition: width .5s ease-in-out;
  transition: width .5s ease-in-out;

  .value {
    font-size: 16px;
    margin: auto auto;
  }
}
.bar-element:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.bar-element:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.list li::before {
  content: "";
  font-weight: bold;
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 10px;
  margin-left: -40px;
}
.li1::before {
  background-color: #0cd0a1;
}
.li2::before {
  background-color: #445E93;
}
.li3::before {
  background-color: #ffd4a4;
}
.li4::before {
  background-color: #eb445a;
}
</style>
